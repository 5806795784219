import React from "react"

import Service from './service';

export default ({ pageContext }) => {
  const { services, scriptures } = pageContext;

  const service = services.find(({ postDate }) => {
    return new Date(postDate * 1000) < new Date();
  });

  const servicePageContext = { service, scriptures };

  return <Service pageContext={servicePageContext} />;
};
